import React, { useState, useEffect } from "react";
import CategoryCard from "./CategoryCard";
// import {testCategory} from "../../Test-Data/categories";

function WhatKindOfQuester() {
  const [categories, setCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState("None");

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesRes = await fetch(`${API_BASE_URL}/categories`);
        if (!categoriesRes.ok) {
          throw new Error(`HTTP error! Status: ${categoriesRes.status}`);
        }
        const categoriesData = await categoriesRes.json();
        setCategories(categoriesData);
      } catch (err) {
        console.error("Fetch error:", err);
      }
    };
    fetchCategories();
  }, [API_BASE_URL]);

  const sortOrders = {
    Adventure: [1, 5, 0],
    Home: [2, 4, 3],
    Escape: [2, 5, 4],
    None: [0, 1, 2, 3, 4, 5],
  };

  const handleTabClick = (tabId) => {
    setSelectedTab((prev) => (prev === tabId ? "None" : tabId));
  };

  const getTabClass = (tabId) => {
    return `text-center p-4 cursor-pointer transition-colors duration-200 ${
      selectedTab === tabId ? "bg-orange-500 text-white" : "hover:bg-orange-100"
    }`;
  };

  const showCategories = (tab) => {
    const sortedCategories = [...categories].sort(
      (a, b) => b.published - a.published
    );

    if (tab === "None") {
      return sortedCategories.map((category, index) => (
        <CategoryCard key={index} category={category} />
      ));
    }

    return sortedCategories.map((category, index) =>
      sortOrders[tab].includes(index) ? (
        <CategoryCard key={index} category={category} />
      ) : null
    );
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 WhatKindOfQuesterContainer">
      <div className="WhatKindOfQuester" id="WhatKindOfQuester">
                <h1 ><span style={{color: '#F6511D'}}> What Kind of</span> <br /> Quester Are You?  </h1>
            </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 custom_flex">
        <button
          id="Adventure"
          style={{ backgroundColor: categories[0]?.secondary_color }}
          onClick={() => handleTabClick("Adventure")}
          className={`${getTabClass("Adventure")}`}
        >
          I Want To Go On An Adventure!
        </button>

        <button
          id="Home"
          style={{ backgroundColor: categories[1]?.secondary_color }}
          onClick={() => handleTabClick("Home")}
          className={getTabClass("Home")}
        >
          Solving Scary Mysteries! <br />
          From The Safety Of My Couch
        </button>

        <button
          id="Escape"
          style={{ backgroundColor: categories[2]?.secondary_color }}
          onClick={() => handleTabClick("Escape")}
          className={getTabClass("Escape")}
        >
          I Want To Share My Awesome Escape-Room
          <br />
          Skills With All My Colleagues
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 CategoryCardContainer">
        {showCategories(selectedTab)}
      </div>
    </div>
  );
}

export default WhatKindOfQuester;
