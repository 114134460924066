import React from "react";
import TrendingCarousel from "./TrendingCarousel";

function Trending() {
  return (
    <div className="Trending">
      <h1>Trending</h1>
      <TrendingCarousel />
      <div id="WhyChooseUs"></div>
    </div>
  );
}

export default Trending;
